"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.attachV2API = void 0;
const utils_1 = require("./utils");
const isMobileDevice = (ua) => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(ua);
};
const isIOS = (ua) => {
    return /iPad|iPhone|iPod/i.test(ua);
};
const getPlayer = (targetId) => {
    return window.KalturaPlayer.getPlayer(targetId);
};
const addKWidgetAPI = (targetId) => {
    var _a;
    const ua = (_a = window.navigator) === null || _a === void 0 ? void 0 : _a.userAgent;
    if (!ua) {
        utils_1.logger.log('User agent is not available; Not adding kWidget API.');
        return;
    }
    window.kWidget = Object.assign(Object.assign({}, window.kWidget), { getKalturaThumbUrl: () => { var _a; return ((_a = getPlayer(targetId)) === null || _a === void 0 ? void 0 : _a.poster) || ''; }, isMobileDevice: () => { var _a; return ((_a = getPlayer(targetId)) === null || _a === void 0 ? void 0 : _a.env.isMobile) || isMobileDevice(ua); }, supportsHTML5: () => true, supportsFlash: () => false, isIOS: () => { var _a; return ((_a = getPlayer(targetId)) === null || _a === void 0 ? void 0 : _a.env.isIOS) || isIOS(ua); }, isIE: () => false, isIE8: () => false, isAndroid: () => /Android/i.test(ua), isWindowsDevice: () => /Windows/i.test(ua), addReadyCallback: () => { }, destroy: () => { var _a; return ((_a = getPlayer(targetId)) === null || _a === void 0 ? void 0 : _a.destroy()) || {}; }, api: () => { }, apiOptions: () => { }, settingsObject: () => { }, jsCallbackReady: () => { } });
    utils_1.logger.log('Finished adding kWidget API.');
};
const addAPIToPlayerElement = (targetId) => {
    const playerDiv = document.getElementById(targetId);
    if (!playerDiv) {
        utils_1.logger.log('Player element is not available; Not adding V2 API.');
        return;
    }
    const playerEl = playerDiv;
    // The purpose of adding the following api functions is to prevent console errors
    playerEl.sendNotification = () => { };
    playerEl.kBind = () => { };
    playerEl.kUnbind = () => { };
    playerEl.evaluate = () => { };
    playerEl.setKDPAttribute = () => { };
    playerEl.removeJsListener = () => { };
    utils_1.logger.log('Finished adding V2 API to the player element.');
};
const attachV2API = (targetId) => {
    utils_1.logger.log('Adding support to V2 API');
    addKWidgetAPI(targetId);
    addAPIToPlayerElement(targetId);
};
exports.attachV2API = attachV2API;
