"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.thumbnailEmbed = void 0;
const thumbnail_embed_component_1 = require("./thumbnail-embed-component");
const events_converter_1 = require("../v2-to-v7/events-converter");
const api_converter_1 = require("../v2-to-v7/utils/api-converter");
const DEFAULT_CDN_URL = 'https://cdnapisec.kaltura.com';
const DEFAULT_WIDTH = 1920;
const DEFAULT_HEIGHT = 1080;
const getCdnUrl = (config) => {
    var _a, _b, _c, _d;
    if ((_a = config.provider.env) === null || _a === void 0 ? void 0 : _a.cdnUrl) {
        return (_b = config.provider.env) === null || _b === void 0 ? void 0 : _b.cdnUrl;
    }
    const uiConfData = window.__kalturaplayerdata;
    if (uiConfData) {
        return (_d = (_c = uiConfData.provider) === null || _c === void 0 ? void 0 : _c.env) === null || _d === void 0 ? void 0 : _d.cdnUrl;
    }
    return DEFAULT_CDN_URL;
};
const thumbnailEmbed = ({ config, mediaInfo, mediaOptions = {}, version, bgColor }, isV2ToV7 = false) => {
    if (!(config && mediaInfo)) {
        return;
    }
    const cdnUrl = getCdnUrl(config);
    const { targetId, provider: { partnerId, ks } } = config;
    const KalturaPlayer = window.KalturaPlayer;
    var playerDiv = document.getElementById(targetId);
    if (!playerDiv || !KalturaPlayer || (KalturaPlayer.getPlayer && KalturaPlayer.getPlayer(targetId))) {
        return;
    }
    let listenersQueue = [];
    if (isV2ToV7) {
        playerDiv.addJsListener = (eventName, callback) => {
            listenersQueue.push({ eventName, eventCallback: callback });
        };
        (0, api_converter_1.attachV2API)(targetId);
    }
    let width = DEFAULT_WIDTH;
    let height = DEFAULT_HEIGHT;
    if (playerDiv.clientWidth && playerDiv.clientHeight) {
        width = playerDiv.clientWidth;
        height = playerDiv.clientHeight;
    }
    const src = `${cdnUrl.endsWith('/') ? cdnUrl : cdnUrl + '/'}` +
        `p/${partnerId}` +
        `/sp/${partnerId}00` +
        '/thumbnail' +
        `/entry_id/${mediaInfo.entryId}` +
        `/version/${version}` +
        `/width/${width}` +
        `/height/${height}` +
        (ks ? `/ks/${ks}` : '');
    const { h, render } = KalturaPlayer.ui.preact;
    render(h(thumbnail_embed_component_1.ThumbnailEmbedComponent, {
        src,
        bgColor,
        onClick: () => {
            try {
                const kalturaPlayer = KalturaPlayer.setup(config);
                listenersQueue.forEach((listenerDetails) => (0, events_converter_1.attachV7Listener)(listenerDetails, kalturaPlayer));
                kalturaPlayer.loadMedia(mediaInfo, mediaOptions);
                kalturaPlayer.play();
            }
            catch (e) {
                /* */
            }
        }
    }), playerDiv);
};
exports.thumbnailEmbed = thumbnailEmbed;
