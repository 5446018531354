"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mergeDeep = exports.logger = exports.getMediaInfo = exports.getConfigIdsFromV2Config = void 0;
const PLAYLIST_KPL0ID = 'playlistAPI.kpl0Id';
const ENTRY_ID = 'entry_id';
const getConfigIdsFromV2Config = (config) => {
    return {
        targetId: config['targetId'],
        partnerId: config['wid'].match(/\d+/g).join(''),
        mediaInfo: (0, exports.getMediaInfo)(config)
    };
};
exports.getConfigIdsFromV2Config = getConfigIdsFromV2Config;
const getMediaInfo = (config) => {
    var _a;
    if (config[ENTRY_ID]) {
        return {
            id: config[ENTRY_ID],
            isPlaylist: false
        };
    }
    let playlistId = '';
    const flashvars = config.flashvars;
    if (flashvars.hasOwnProperty(PLAYLIST_KPL0ID)) {
        playlistId = flashvars[PLAYLIST_KPL0ID];
    }
    else if ((_a = flashvars.playlistAPI) === null || _a === void 0 ? void 0 : _a.kpl0Id) {
        playlistId = flashvars.playlistAPI.kpl0Id;
    }
    return {
        id: playlistId,
        isPlaylist: true
    };
};
exports.getMediaInfo = getMediaInfo;
const LOGGER_NAME = '[V2 To V7]';
exports.logger = {
    log: (...args) => console.info(`${LOGGER_NAME}`, ...args),
    error: (...args) => console.error(`${LOGGER_NAME}`, ...args)
};
const mergeDeep = (target, source) => {
    const result = Object.assign({}, target); // Start with a shallow copy of the target
    Object.keys(source).forEach(key => {
        if (source[key] && typeof source[key] === 'object' && !Array.isArray(source[key])) {
            if (!result[key]) {
                result[key] = {};
            }
            result[key] = (0, exports.mergeDeep)(result[key], source[key]);
        }
        else {
            result[key] = source[key];
        }
    });
    return result;
};
exports.mergeDeep = mergeDeep;
