"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.attachV7Listener = void 0;
const utils_1 = require("./utils/utils");
/**
 * A key mapping, where the key is the V2 event name and the value is the corresponding V7 event name.
 */
const eventsKeyMapping = {
    // playback
    "mediaLoaded": "medialoaded",
    "mediaReady": "medialoaded",
    "sourceReady": "sourceselected",
    "playerReady": "sourceselected",
    "firstPlay": "firstplay",
    "playerPlayEnd": "playbackended",
    "playbackComplete": "playbackended",
    "metadataReceived": "loadedmetadata",
    // user interaction
    "userInitiatedPlay": "playkit-ui-userclickedplay",
    "userInitiatedPause": "playkit-ui-userclickedpause",
    "userInitiatedSeek": "playkit-ui-userseeked",
    // core functionality
    "playerPaused": "pause",
    "playerPlayed": "play",
    "seek": "seeking",
    "openFullScreen": "enterfullscreen",
    "closeFullScreen": "exitfullscreen",
    // playlist
    "playlistReady": "kaltura-player-playlistloaded",
};
const attachV7Listener = (listenerDetails, kalturaPlayer) => {
    const { eventName, eventCallback } = listenerDetails;
    if (eventsKeyMapping[eventName]) {
        kalturaPlayer.addEventListener(eventsKeyMapping[eventName], () => {
            eventCallback();
        });
    }
    else {
        switch (eventName) {
            // PLAYBACK
            case 'durationChange':
                kalturaPlayer.addEventListener('durationchange', (event) => {
                    eventCallback(event.currentTarget.duration);
                });
                break;
            case 'playerStateChange':
                kalturaPlayer.addEventListener('playerstatechanged', (event) => {
                    eventCallback(event.payload.newState.type);
                });
                break;
            case 'playerUpdatePlayhead':
                kalturaPlayer.addEventListener('timeupdate', (event) => {
                    eventCallback(event.currentTarget.currentTime);
                });
                break;
            case 'changeMediaProcessStarted':
                kalturaPlayer.addEventListener('changesourcestarted', (event) => {
                    eventCallback(event.currentTarget.sources.id);
                });
                break;
            case 'switchingChangeComplete':
                kalturaPlayer.addEventListener('videotrackchanged', (event) => {
                    eventCallback({ currentBitrate: event.payload.selectedVideoTrack.height });
                });
                break;
            case 'cuePointReached':
                kalturaPlayer.addEventListener('timedmetadatachange', (event) => {
                    eventCallback(event.payload.cues);
                });
                break;
            case 'cuePointsReceived':
                kalturaPlayer.addEventListener('timedmetadataadded', (event) => {
                    eventCallback(event.payload.cues);
                });
                break;
            // CORE FUNCTIONALITY
            case 'seeked':
                kalturaPlayer.addEventListener('seeked', (event) => {
                    eventCallback(event.currentTarget.currentTime);
                });
                break;
            case 'volumeChanged':
                kalturaPlayer.addEventListener('volumechange', (event) => {
                    const player = event.currentTarget;
                    eventCallback(player.muted ? 0 : player.volume);
                });
                break;
            case 'mute':
                kalturaPlayer.addEventListener('mutechange', (event) => {
                    if (event.currentTarget.muted) {
                        eventCallback();
                    }
                });
                break;
            case 'unmute':
                kalturaPlayer.addEventListener('mutechange', (event) => {
                    if (!event.currentTarget.muted) {
                        eventCallback();
                    }
                });
                break;
            case 'closedCaptionsHidden':
                kalturaPlayer.addEventListener('texttrackchanged', (event) => {
                    if (event.payload.selectedTextTrack.language === 'off') {
                        eventCallback();
                    }
                });
                break;
            case 'closedCaptionsDisplayed':
                kalturaPlayer.addEventListener('texttrackchanged', (event) => {
                    const language = event.payload.selectedTextTrack.language;
                    if (language !== 'off') {
                        eventCallback(language);
                    }
                });
                break;
            case 'changedClosedCaptions':
                kalturaPlayer.addEventListener('texttrackchanged', (event) => {
                    eventCallback(event.payload.selectedTextTrack.language);
                });
                break;
            // PLAYLIST EVENTS
            case 'playlistFirstEntry':
                kalturaPlayer.addEventListener('kaltura-player-playlistitemchanged', (event) => {
                    if (event.payload.index === 0) {
                        eventCallback();
                    }
                });
                break;
            case 'playlistMiddleEntry':
                kalturaPlayer.addEventListener('kaltura-player-playlistitemchanged', (event) => {
                    const lastIndex = event.currentTarget._playlistManager.items.length - 1;
                    const currentIndex = event.payload.index;
                    if (currentIndex > 0 && currentIndex < lastIndex) {
                        eventCallback();
                    }
                });
                break;
            case 'playlistLastEntry':
                kalturaPlayer.addEventListener('kaltura-player-playlistitemchanged', (event) => {
                    const lastIndex = event.currentTarget._playlistManager.items.length - 1;
                    if (event.payload.index === lastIndex) {
                        eventCallback();
                    }
                });
                break;
            // PLUGINS
            case 'pluginsLoaded':
                kalturaPlayer.addEventListener('registeredpluginslistevent', (event) => {
                    eventCallback(event.payload);
                });
                break;
            case 'relatedVideoSelect':
                kalturaPlayer.addEventListener('related_entry_selected', (event) => {
                    eventCallback({ entryId: event.currentTarget._mediaInfo.entryId });
                });
                kalturaPlayer.addEventListener('related_entry_auto_played', (event) => {
                    eventCallback({ entryId: event.currentTarget._mediaInfo.entryId });
                });
                break;
            // ERRORS
            case 'entryNotAvailable':
                kalturaPlayer.addEventListener('error', (event) => {
                    const errorCategory = event.payload.category;
                    if ([12, 13, 14].includes(errorCategory) || (errorCategory === 7 && event.payload.code === 7002)) {
                        /*
                        category 12: MEDIA_NOT_READY
                        category 13: GEO_LOCATION
                        category 14: MEDIA_UNAVAILABLE
                        category 7 and code 7002: media load failure
                        */
                        eventCallback();
                    }
                });
                break;
            case 'mediaError':
                kalturaPlayer.addEventListener('error', (event) => {
                    const errorCategory = event.payload.category;
                    if (errorCategory === 3) {
                        // category 3: MEDIA error
                        eventCallback();
                    }
                });
                break;
            default:
                utils_1.logger.log(`The event: '${eventName}' is not supported.`);
                break;
        }
    }
};
exports.attachV7Listener = attachV7Listener;
